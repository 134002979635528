@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:wght@100;300;400;700;900&display=swap');
$mobile-app: 400px;
$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1900 : 1900px;
@mixin  transition3 (){
    -webkit-transition: all 0.3s ease;
    -moz-transition   : all 0.3s ease;
    -ms-transition    : all 0.3s ease;
    -o-transition     : all 0.3s ease;
    transition        : all 0.3s ease;
}

@mixin  transition5 (){
    -webkit-transition: all 0.5s ease;
    -moz-transition   : all 0.5s ease;
    -ms-transition    : all 0.5s ease;
    -o-transition     : all 0.5s ease;
    transition        : all 0.5s ease;
}

@mixin  transitionY (){
    -webkit-transform: translateY(-50%);
    -ms-transform    : translateY(-50%);
    -o-transform     : translateY(-50%);
    transform        : translateY(-50%);
}

@mixin  transitionY3 (){
    -webkit-transform : translateY(30px);
    -ms-transform     : translateY(30px);
    -o-transform      : translateY(30px);
    transform         : translateY(30px);
}

@mixin  transitionY0 (){
    -webkit-transform : translateY(0);
    -ms-transform     : translateY(0);
    -o-transform      : translateY(0);
    transform         : translateY(0);
}


@mixin flexitem {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}


@mixin flex($alignItem, $justifyContent) {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: $alignItem;
    justify-content: $justifyContent;
}

@mixin  absolute (){
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}


@mixin  topcenter (){
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin  centeritem (){
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}


@mixin size ($width , $height) {
    width: $width;
    height: $height;
}
@mixin mobileapp {
    @media only screen and (max-width: $mobile-app) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}

@mixin desktop-1200 {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin desktop-1400 {
    @media only screen and (max-width: 1400px) {
        @content;
    }
}

@mixin desktop-1650 {
    @media only screen and (max-width: 1650px) {
        @content;
    }
}
@mixin desktop-1750 {
    @media only screen and (max-width: 1750px) {
        @content;
    }
}
@mixin desktop-1900 {
    @media only screen and (max-width: $desktop-1900) {
        @content;
    }
}
html,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Chakra Petch, sans-serif';
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: var(--background-body);
    color: var(--primary-color);
    font-size: 16px;
    line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

blockquote,
q {
    quotes: none
}

a img {
    border: 0
}

img {
    max-width: 100%;
    height: auto;
}

select {
    max-width: 100%
}

body,
button,
input,
select,
textarea {
    font-family: 'Chakra Petch, sans-serif';
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-y: auto;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic
}

.text-p,
p {
    font-weight: 600;
}

strong,
b,
cite {
    font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
    font-style: italic;
}

abbr,
acronym {
    border-bottom: 1px dotted #e0e0e0;
    cursor: help;
}

mark,
ins {
    text-decoration: none;
}

sup,
sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

small {
    font-size: 75%;
}

big {
    font-size: 125%;
}

address {
    font-style: italic;
    margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
    margin: 20px 0;
    padding: 4px 12px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    border-radius: 0;
    height: auto;
}


html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

hr {
    margin-bottom: 20px;
    border: dashed 1px #ccc;
}

ul,
ol {
    padding: 0;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

li {
    list-style: none;
}

ul li,
ol li {
    padding: 0.1em 0;
}

dl,
dd {
    margin: 0 0 20px;
}

dt {
    font-weight: bold;
}

del,
.disable {
    text-decoration: line-through;
    filter: alpha(opacity=50);
    opacity: 0.5;
}


table,
th,
td {
    border: 1px solid #343444;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

th {
    border-width: 0 1px 1px 0;
    font-weight: bold;
}

td {
    border-width: 0 1px 1px 0;
}

th,
td {
    padding: 8px 12px;
}


embed,
object,
video {
    margin-bottom: 20px;
    max-width: 100%;
    vertical-align: middle;
}

p>embed,
p>iframe,
p>object,
p>video {
    margin-bottom: 0;
}

button,
input {
    line-height: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    vertical-align: baseline;

}

input,
textarea,
select {
    font-size: 14px;
    max-width: 100%;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

textarea {
    overflow: auto;
    vertical-align: top;
    height: 138px;
}

input[type="checkbox"] {
    display: inline;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: button;
    appearance: button;
    border: 0;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    width: 14px;
    height: 14px;
    margin-right: 11px;
    cursor: pointer;
    vertical-align: sub;
}

input[type="search"] {
    -webkit-appearance: textfield;
    appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
    box-shadow: 0 0 0px 1000px #f7f7f7 inset
}


input[type="search"] {
    outline: 0
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.wpcf7-form select,
.wpcf7-form textarea,
.wpcf7-form input {
    margin-bottom: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
}

.select {
    position: relative;
    overflow: hidden;
    ::after {
        font-family: 'Font Awesome 5 Pro';
        content: '\f078';
        font-size: 14px;
        right: 10px;
        font-weight: 600;
        color: #1F1F2C;
        display: block;
        position: absolute;
        background: 0 0;
        top: 50%;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

select option {
    font-size: 15px;
    line-height: 24px;
    color: #1F1F2C;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    border: 1px solid var(--speciex-border);
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 18px;
    line-height: 26px;
    border-radius: 4px;
    padding: 15px;
    width: 100%;
    background-color: transparent;
    color: var(--primary-color2);
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    border: 1px solid var(--speciex-primary);
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: var(--primary-color2);
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font-weight: 700;
    font-size: 16px;
    line-height:24px;
    background-color: #FF008E;
    color: #fff;
    border-radius: 50px;
    padding: 17px 50px;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    @include transition3;
    &:focus,
    &:hover {
        outline: 0;
        background: #FF008E;
        color: #fff;           
    }
}


::-webkit-input-placeholder {
    color: var(--primary-color2);
}

:-moz-placeholder {
    color: var(--primary-color2);
}

::-moz-placeholder {
    color: var(--primary-color2);
    opacity: 1;
}

.error {
    font-size: 16px;
    color: red;
    margin-bottom: 10px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}


:-ms-input-placeholder {
    color: var(--primary-color2);
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 70px;
    line-height: 80px;
}

h2 {
    font-size: 44px;
    line-height: 56px;

}

h3 {
    font-size: 40px;
    line-height: 56px;

}

h4 {
    font-size: 36px;
    line-height: 44px;
}

h5 {
    font-size: 30px;
    line-height: 42px;
}

h6 {
    font-size: 24px;
    line-height: 32px;
}


.msg-success {
    background: linear-gradient(to right, #E250E5, #4B50E6, #E250E5);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    color: rgba(0, 0, 0, 1);
    transition: color .2s ease-in-out;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

label.error {
    color: red;
}
.swiper-button-prev,
.swiper-button-next {
    width: 33.3px;
    height: 33.3px;
    top: 53%;
    @include mobile {
        display: none;
    }
}

.swiper-button-next {
    right: -2px;
}
.swiper-button-prev {
    left: -2px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: 'Font Awesome 5 Pro';
    font-size: 20px;
    width: 33.3px;
    height: 33.3px;
    background-color: #fff;
    color:#191820 ;
    border: 1.5px solid #E5E5E5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next:after {
    content: "\f105";
}

.swiper-button-prev:after {
    content: "\f104";
}

.swiper-pagination-bullets {
    position: relative;
    margin-top: 13px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    justify-items: center;
    padding-left: 15px;
    .swiper-pagination-bullet {
        margin: 0 11px 0 5px;
        border: 1px solid var(--primary-color5);
        background: transparent;
        width: 8px;
        height: 8px;
        position: relative;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: var(--primary-color5);
    }
    .swiper-pagination-bullet-active::before {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        border-radius: 50%;
        content: "";
        width: 16px;
        height: 16px;
        border: 1px solid var(--primary-color5);
        overflow: visible;
    }
}

.tf-container {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    width        : 1440px !important;
    max-width    : 100%;
}
.mb63 {
    margin-bottom: 63px;
}

.mb44 {
    margin-bottom: 44px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb30 {
    margin-bottom: 40px;
}

.mb28 {
    margin-bottom: 28px;
}
.mb26 {
    margin-bottom: 26px;
}
.mb23 {
    margin-bottom: 23px;
}
.mb22 {
    margin-bottom: 22px;
}
.mt17 {
    margin-top: 17px;
}
.mt12 {
    margin-top: 12px;
}
.mt10 {
    margin-top: 10px;
}

.mt8 {
    margin-top: 8px;
}

.mt6 {
    margin-top: 6px;
}

.pt10 {
    padding-top: 10px;
}
.visible {
    overflow: visible !important;
}

a {
    text-decoration: none;
    color: var(--primary-color5);
    @include transition3;

    &:hover,
    &:focus {
        color: var(--speciex-primary);
        text-decoration: none;
        outline: 0;
        @include transition3;
    }
}

@keyframes rainbow {
    0% {
        background-position: left
    }

    50% {
        background-position: right
    }

    100% {
        background-position: left
    }
}


:root {
    --speciex-border: #f2caff ;
    --speciex-btn: #2C292D  ;
    --speciex-grey: rgba(38, 38, 38, 0.5);
    --speciex-primary:#5C2270;
    --speciex-sec:rgba(38, 38, 38, 0.5);
    --speciex-head:linear-gradient(94.44deg, #000000 0%, #353535 100%);
    --speciex-bg: linear-gradient(89.84deg, #FCF2FF 9.15%, #F5DAFF 46.48%, #FCF2FF 87.78%);
    --background-body: #fff;
    --primary-color: #565660;
    --primary-color2: rgba(86, 86, 96, 0.6);
    --primary-color3: #FF008E;
    --primary-color4: #E5E5E5;
    --primary-color5: #191820;
    --primary-color6: #fff;
    --primary-color7: #565660;
    --primary-color8: #565660;
    --primary-color9: #F7F7F7;
    --primary-color10: #B9B8BB;
    --primary-color11: #F7F7F7;
    --primary-color12: rgba(229, 229, 229, 0.5);
    --primary-color13: rgba(55, 73, 233, 0.1);
    --primary-color14: #FF008E;
    --primary-color15: #E5E5E5;
    --primary-color16: #191820;
    --primary-color17: #E5E5E5;
    --primary-color18: #565660;
    --primary-color19: #565660;
    --primary-color20: #191820;
    --primary-color21: #E5E5E5;
    --primary-color22: #F7F7F7;
    --primary-color23: rgba(86, 86, 96, 0.8);
    --primary-color24: rgba(55, 73, 233, 0.1);
    --primary-color25: rgba(55, 73, 233, 0.1);
    --primary-color26: #48BC65;
    --primary-color27:#fff;
    --primary-color28:#B9B9BF;
    --primary-color29:#F7F7F7;
    --primary-color30:#565660;
    --primary-color31: #B9B8BB;
    --primary-color32: #CCD2E3;
    --primary-color33: #FF008E;
    --primary-color34: #B9B8BB;
    --primary-color35:rgba(55, 73, 233, 0.1);
    --primary-color36: #E5E5E5;
    --primary-color37: #191820;
    --primary-color38: #E5E5E5;
    --primary-color39:#F7F7F7;
    --primary-color40: rgba(55, 73, 233, 0.1);
    --primary-color41: #F7F7F7;
    --primary-color42: #CFDBD5;
    --primary-color43: #FF008E;
    --primary-color44: #fff;
    --primary-color45: rgba(55, 73, 233, 0.1);
    --primary-color46: rgba(86, 86, 96,0.1);
    --primary-color47: #E5E5E5;
    --primary-color48: #FF008E;
    --primary-color49: #BBBAC2;
    --primary-color50: linear-gradient(141.18deg, rgba(0, 0, 0, 0) -1.4%, rgba(0, 0, 0, 0.0568) -1.38%, rgba(0, 0, 0, 0.0096) 113.47%);
    --primary-color51:#B9B8BB;
    --primary-color52:transparent;



    --product-color1: #E33B3B;
    --product-color2: #E5E5E5;
    --product-color3: #B9B8BB;
    --product-color4: #fff;
    --product-color5: #B9B8BB;
    --product-color6: #E5E5E5;
    --product-color7: #fff;
    --product-color8: #fff;
    --product-color9: rgba(86, 86, 96, 0.8);
    --product-color10: transparent;
    --product-color11: #E5E5E5;
    --product-color12: #B9B9BF;
    --product-color13: #fff;
    --product-color14: #fff;
    --product-color15: #E5E5E5;
    --product-color16: transparent;
    --product-color17: transparent;
    --product-color18: #E5E5E5;
    --product-color19: rgba(55, 73, 233, 0.1);


    
}

.is_dark {
    --background-body: #191820;
    --primary-color: rgb(247, 247, 247,0.8);
    --primary-color2: #8A8AA0;
    --primary-color3: #FF008E;
    --primary-color4: #28303F;
    --primary-color5: #fff;
    --primary-color6: #1F1F2C;
    --primary-color7: #fff;
    --primary-color8: #B9B8BB;
    --primary-color9: rgba(86, 86, 96, 0.1);
    --primary-color10: #565660;
    --primary-color11: #28303F;
    --primary-color12: rgba(229, 229, 229, 0.5);
    --primary-color13:  rgba(55, 73, 233, 0.2);
    --primary-color14: #fff;
    --primary-color15: #1F1F2C;
    --primary-color16: #F7F7F7;
    --primary-color17: rgba(40, 48, 63, 0.6);
    --primary-color18: #F7F7F7;
    --primary-color19: #EBEBEB;
    --primary-color20: #EBEBEB;
    --primary-color21: #28303F;   
    --primary-color22: #1F1F2C;
    --primary-color23: #EBEBEB;
    --primary-color24: rgba(0, 0, 0, 0);
    --primary-color25: #FF008E;
    --primary-color26: #FF008E;
    --primary-color27:#2B3F6C;
    --primary-color28:#fff;
    --primary-color29:rgba(255, 255, 255, 0.3);
    --primary-color30:#A9A9C2;
    --primary-color31: #F7F7F7;
    --primary-color32: #565660;
    --primary-color33: #1F1F2C;
    --primary-color34: #28303F;
    --primary-color35:#1F1F2C;
    --primary-color36: #565660;
    --primary-color37: #B9B8BB;
    --primary-color38: #FF008E;
    --primary-color39:rgba(255, 255, 255, 0.1);
    --primary-color40: rgba(255, 255, 255, 0.1);
    --primary-color41: transparent;
    --primary-color42: #CFDBD5;
    --primary-color43: #565660;
    --primary-color44: rgba(255, 255, 255, 0.1);
    --primary-color45: rgba(235, 240, 240, 0.1);
    --primary-color46: #565660;
    --primary-color47: rgba(86, 86, 96, 0.2);
    --primary-color48: #555ABE;
    --primary-color49: #B9B8BB;
    --primary-color50: linear-gradient(141.18deg, rgba(0, 0, 0, 0) -1.4%, rgba(0, 0, 0, 0.0568) -1.38%, rgba(0, 0, 0, 0.0096) 113.47%);
    --primary-color51:#A9A9C2;
    --primary-color52:#FF008E;



    
    --product-color2: #B9B8BB;
    --product-color4: linear-gradient(156.67deg, rgba(112, 112, 114, 0.4) 0.07%, rgba(100, 97, 113, 0.148) 99.07%);
    --product-color5: #fff;
    --product-color6: #fff;
    --product-color8: #1F1F2C;
    --product-color9: rgba(255, 255, 255, 0.1);
    --product-color10: rgba(255, 255, 255, 0.1);
    --product-color11: #56566046;
    --product-color12: #B9B8BB;
    --product-color13: #292831;
    --product-color14: #565660;
    --product-color15: transparent;
    --product-color16: #FF008E;
    --product-color17: #1F1F2C;
    --product-color18: rgba(207, 219, 213, 0.15);
    --product-color19: rgba(255, 255, 255, 0.1);
}


#page {
    overflow: hidden;
}

header {
    border-bottom: 1px solid var(--primary-color4);
    padding-right: 0 !important;
    #site-header-inner {
        @include flex(center,left);
        #site-logo {
            flex-shrink: 0;
            margin-right: 40px;
        }
        #main-nav {
            flex-shrink: 1;
        }
        .header-right {
            margin-left: auto;
            display: inline-flex;
            .tf-button,.user,.mode-switch {
                margin-right: 8px;
            }
            .user,.mode-switch {
                width: 48px;
                height: 48px;
                line-height: 46px;
                text-align: center;
                background-color: var(--primary-color6);
                border: 1px solid var(--primary-color4);
                border-radius: 50%;
                display: inline-block;
            }
            .user {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                svg {
                    path {
                        fill: var(--primary-color8);
                    }
                }
            }
            .mode-switch {
                margin-right: 0;
            }
            .tf-button {
                padding: 11px 35px 11px 37px;
                font-weight: 700;
                font-size: 16px;

            }
        }
    }
    &.absolute {
        position: absolute;
        width: 100%;
        border-bottom: none;
        z-index: 100;
    }
}

#main-nav {
    ul {
        margin: 0px;

        li {
            position: relative;
            list-style: none;
        }
    }
}

#main-nav {
    >ul {
        >li {
            float: left;
            padding: 31px 30px 31px 13px;

            >a {
                position: relative;
                font-size: 16px;
                line-height: 26px;
                font-weight: 600;
                display: block;
                color: var(--speciex-grey);
                padding-right: 15.5px;
                text-transform: uppercase;
                font-family: 'Chakra Petch, sans-serif';

                &:hover {
                    color: var(--speciex-primary);
                }
            }
        }
    }

    >.menu {
        >li {
            &.menu-item-has-children {
                >a {
                    &::after {
                        content: '\f107';
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 300;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        @include transitionY;
                    }
                }
            }
        }
    }
}

#main-nav {
    .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 250px;
        background-color: var(--primary-color6);
        border: 1px solid var(--primary-color15);
        border-radius: 8px;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        transform: translateY(15px);
        max-height: 295px;
        overflow: auto;
        .sub-menu {
            left: 105%;
            top: 0%;
            opacity: 0 !important;
            visibility: hidden ;
            &::before {
                display: none;
            }
        }

        li {
            &:not(:last-child) {
                a {
                    border-bottom: 1px solid var(--primary-color4);
                }
            }

            a {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--primary-color5);
                padding: 10px 15px;
            }
        }
    }

    .right-sub-menu {
        left: auto;
        right: 0;
    }
}


#main-nav > ul > .current-menu-item  .current-menu-item > a,
#main-nav > ul > .current-menu-item > a {
    color: var(--primary-color3);
}

#main-nav li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    max-height: 295px;
    overflow: auto;
}

#main-nav li .sub-menu li:hover .sub-menu {
    opacity: 1 !important;
    visibility: visible;
}



#main-nav .sub-menu li.current-item a,
#main-nav .sub-menu li a:hover {
    color: var(--speciex-primary);
}

#main-nav {
    >ul {
        >li {
            .sub-menu {
                li {
                    position: relative;

                    a {
                        
                        transition: all 0.15s linear;
                        -webkit-transition: all 0.15s linear;
                        -moz-transition: all 0.15s linear;
                        -o-transition: all 0.15s linear;

                        &:hover {
                            padding-left: 35px;

                            &::after {
                                width: 12px;
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 2px;
                            background: var(--speciex-primary);
                            left: 15px;
                            top: 50%;
                            transform: translate(0%,-50%);
                            @include transition3;
                        }
                    }

                    &.current-item {
                        a {
                            padding-left: 35px;

                            &::after {
                                width: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#main-nav-mobi {
    display: block;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999999;
    background-color: var(--primary-color6);

    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        
        li {
            margin: 0;
            text-align: left;
            cursor: pointer;
            padding: 0;
            border-top: 1px solid var(--primary-color4);
            position: relative;

            &:first-child {
                border-top: 0px;
            }
        }
    }
}

#main-nav-mobi {
    ul {
        >li {
            >a {
                color: var(--primary-color5);
                display: inline-block;
                font-size: 16px;
                line-height: 48px;
                padding: 0 15px;
                font-weight: 500;
            }
            &.current-menu-item {
                >a {
                    color: var(--primary-color3);
                }
            }
        }
    }
    .sub-menu {
        .menu-item {
            a {
                margin-left: 15px;
            }
        }
    }
}

#main-nav-mobi ul li>ul>li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
}

#main-nav-mobi .current-item>a,
#main-nav-mobi ul>li>a:hover {
    color: var(--primary-color3);
}

#main-nav-mobi {
    .menu-item-has-children {
        .arrow {
            cursor: pointer;
            display: inline-block;
            font-size: 20px;
            font-family: "FontAwesome";
            line-height: 48px;
            position: absolute;
            right: 15px;
            text-align: center;
            top: 0;
            width: 48px;

            &::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f107";
                color: var(--primary-color2);
            }
        }
    }
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
    color: var(--primary-color3);
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
    background: var(--primary-color3);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

#main-nav-mobi .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    @include transition3;
}

#main-nav-mobi .menu-item-has-children .arrow.active:before {
    content: "\f107";
}

#main-nav-mobi ul ul li {
    background-color: var(--primary-color6);
    border-top: 1px solid var(--primary-color4);
}

.mobile-button {
    display: none;
    position: absolute;
    width: 26px;
    height: 26px;
    float: right;
    top: 50%;
    right: 15px;
    background-color: transparent;
    cursor: pointer;
    @include transition3;
    @include transitionY;
    &::before,
    &::after,
    span {
        background-color: var(--primary-color2);
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 3px;
        width: 100%;
        left: 0;
        top: 50%;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    span {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 50%;
        overflow: hidden;
        text-indent: 200%;
    }

    &::before {
        -webkit-transform: translate3d(0, -7px, 0);
        -moz-transform: translate3d(0, -7px, 0);
        transform: translate3d(0, -7px, 0);
    }

    &::after {
        -webkit-transform: translate3d(0, 7px, 0);
        -moz-transform: translate3d(0, 7px, 0);
        transform: translate3d(0, 7px, 0);
    }

    &.active {
        span {
            opacity: 0;
        }

        &::before {
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            -moz-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
        }

        &::after {
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            -moz-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
        }
    }

}

.tf-button-submit {
    &:hover {
        opacity: .9;
        color: #fff;
    }
}

.is_dark {
    .tf-button-submit {
        &:hover {
            color: #fff;
        }
    }
}

.header {
    &.is-fixed {
        position: fixed;
        top: -88px;
        left: 0;
        width: 100%;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
        z-index: 9999;
        background: var(--background-body);
        border: none;
        opacity: 1;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        &.is-small {
            top: 0;
        }
    }
}

.popup-user {
    display: inline-block;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
    .avatar {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .avatar_popup {
        position: absolute;
        z-index: 100;
        right: 0;
        padding: 31px 20px;
        top: 100%;
        margin-top: 20px;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid var(--primary-color15);
        background: var(--primary-color6);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-1rem);
        transform: translateY(-1rem);
        font-size: 16px;
        width: 311px;
        &.visible {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        h6 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 15px;
            color: var(--primary-color5);
        }
        .title {
            font-size: 14px;
            line-height: 22px; 
            margin-bottom: 5px; 
            color: var(--primary-color5);
        }
        .price {
            margin-bottom: 10px;
            .style {
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: var(--speciex-primary);
            }
        }
        .code {
            @include flex(flex-start,space-between);
            padding-bottom: 14px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--primary-color4);
            p {
                font-size: 14px;
                line-height: 22px;
            }
            svg {
                path {
                    fill:var(--product-color5);
                }
            }
        }
        .links {
            li {
                padding: 8px 0;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
            a {
                @include flex(center,left);
                svg {
                    margin-right: 13px;
                    path {
                        fill:var(--primary-color14);
                    }
                }
                span {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
    }
}

.tf-page-title {
    padding: 24px 0 0;
    &.style-2 {
        padding: 24px 0 15px;
    }
    .breadcrumbs {
        margin-bottom: 43px;
        width: 100%;
        @include flex(center,left);
        li {
            font-weight: 700;
            display: inline-block;
            padding: 0 24px 0 0px;
            color: var(--speciex-primary);
            a {
                font-weight: 700;
                position: relative;
                color: var(--primary-color8);
                padding-right: 33px;
                &::after {
                    content: "\f105";
                    font-family: 'Font Awesome 5 Pro';
                    position: absolute;
                    color: #8A8AA0;
                    right: 0;
                  
                }
        }
        }
    }
    .page-title-heading {
        color: var(--primary-color5) ;
    }
    &.style-2 {
        .breadcrumbs {
            margin-bottom: 0;
        }
    }
}


.themesflat-pagination {
    ul {
        margin-left: 2px;
        position: relative;

        li {
            display: inline-block;
            margin-right: 6.8px;

            .page-numbers {
                display: inline-block;
                font-family: 'Open Sans';
                font-size: 14px;
                font-weight: 600;
                color: #706C83;
                background: var(--primary-color10);
                text-align: center;
                width: 50px;
                height: 40px;
                border-radius: 5px;
                border: 1px solid var(--primary-color6);
                line-height: 40px;
                object-fit: cover;

                &:hover,
                &.current {
                    background-color: var(--primary-color3);
                    color: #fff;
                    border-color: var(--primary-color3);
                }
            }
        }
    }
}


@media only screen and (max-width:575px)
{
#scroll-top {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
}
}
#scroll-top {
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    text-align: center;
    z-index: 999;
    right: 14px;
    bottom: 23px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    overflow: hidden;

    &.show {
        right: 15px;
        opacity: 1;
        visibility: visible;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include transition3;
    }

    &::before {
        z-index: -1;
        background-color: var(--speciex-primary);
    }

    &::after {
        content: "\f077";
        font-family: "Font Awesome 5 Pro";
        font-size: 18px;
        color: #ffffff;
        font-weight: 600;
    }

    &:hover {
        transform: translateY(-7%);
    }
}

.is_dark {
    #scroll-top {
        &::before {
            z-index: -1;
            background-color: #fff;
        }

        &::after {
            color: #5142FC;
        }
    }
}

.footer {
    padding: 25px 0;
    background: linear-gradient(272deg, #FCF2FF 9.15%, #F5DAFF 24.3%, rgba(252, 242, 255, 0.29) 87.78%);

    .widget {
        margin-top: 0px;
        &.widget-infor {
            padding-right: 0%;
            margin-top: 0;
            .logo {
                margin-bottom: 20px;
            }
            .content {
                margin-bottom: 23px;
                color: #7E7780;
            }
            .social-item {
                @include flexitem;
                margin-bottom: 30px;
                li {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background-color: var(--primary-color11);
                    border-radius: 8px;
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .copy-right {
                color: var(--primary-color10);
                font-size: 14px;
                line-height: 22px;
            }
        }
        &.widget-menu {
            @include flexitem;
            .menu {
                ul  li {
                    padding-bottom: 11px;
                    a {
                        color: #141414;;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 500;
                        &:hover {
                            color: var(--speciex-primary);
                        }
                    }
                    &:last-child {
                        padding-bottom: 0;
                    }
                } 
                &.menu-1 {
                    margin-left: 15px;
                }
                &.menu-2 {
                    margin-left: 61px;
                }
                &.menu-3 {
                    margin-left: 60px;
                }
                &.menu-4 {
                    margin-left: 63px;
                }
            }
        }
        &.widget-subcribe {
            margin-left: 20px;
            margin-top: 0px;
            .content {
                margin-bottom: 16px;
                font-size: 14px;
                line-height: 22px;
                color:#222123;
                letter-spacing: -0.2px;
                padding-right: 5px;
            }
            #subscribe-form {
                position: relative;
                input {
                    // border: 1px solid var(--primary-color8);
                    border-radius: 43px;
                    width: 100%;
                    padding: 12px 46px 12px 20px;
                    font-size: 14px;
                    line-height: 22px;
                    color: var(--primary-color8);
                    background: #fff;
                    &::placeholder {
                        font-size: 14px;
                        color: var(--primary-color8);
                    }
                    &:focus {
                        border-color: var(--speciex-primary);
                    }
                }

                .tf-button {
                    right: 0;
                    position: absolute;
                    height: 100%;
                    font-size: 12px;
                    width: 80px;
                    padding: 0;
                    background-color: var(--speciex-primary);
                    border: 1px solid var(--speciex-primary);
                    color: #fff;
                    border-radius:  40px ;
                    font-weight: 500;
                }
            }
            
        }
        .widget-title {
            font-size: 18px;
            line-height: 24px;
            color: var(--primary-color5) ;
            margin-bottom: 20px;
            font-family: "'Chakra Petch, sans-serif'" !important;
        }
    }
}

.preload {
    overflow: hidden;
}

.preload-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999999999;
    overflow: hidden;
    animation: stop 2s !important;
}

@keyframes stop {
    0%{
        display:block !important;
    }
    100%{
        display:none !important;
    }
}
.is_light .preload-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    z-index: 100;
    margin: -200px 0 0 -200px;
    background: #ffffff;
    background: url("../images/hi_spicex.svg") center center no-repeat;
    animation:
        preload 1s linear linear alternate;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}
.is_dark .preload-container {
    background: #1f1f2c !important;
}
.is_dark .preload-logo {

    background: url("../images/hi_spicex.svg") center center no-repeat;
    animation:
        preload 1s linear linear alternate;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}


.tf-blog {
    padding: 28px 0 80px 0;
    .tf-blog-item {
        box-sizing: border-box;
        padding: 24px 24px 32px 24px;
        background: #fff;
        border-radius: 20px;
        margin-bottom: 30px;
        border: 1px solid #E5E5E5;
        .image {
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 28px;
            img {
                width: 100%;
            }
        }
        .title {
            margin-bottom: 20px;
            @include flex(left,space-between);
            .tf-button {
                padding: 8px 9px;
                border-radius: 20px;
                background:#FF008E;
                color: #fff;
            }
        }
        .content {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 26px;
        }
        .meta {
            &> span {
                margin-right: 26px;
                font-size: 18px;
                line-height: 26px;

                i {
                    margin-right: 7px;
                    color: var(--primary-color3);
                }
            }
            .admin {
                i {
                    font-size: 15px;
                    margin-left: 2px;
                }
            }
        }
        &.is_dark {
            background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.1) 0.07%, rgba(100, 97, 113, 0.037) 99.07%);
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
        }
        &.style-2 {
            padding: 24px 24px 30px 24px;
            .title {
                margin-bottom: 18px;
            }
            .content {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 15px;
            }
        }
    }
    .load-more {
        text-align: center;
        margin-top: 10px;
        .tf-button {
            border-radius: 50px;
            font-size: 14px;
            padding: 10px 23px;
            background-color: var(--primary-color24);
            border: 1px solid var(--primary-color25);
            color: var(--primary-color14);
            font-weight: 700;
            &:hover {
                background-color: #FF008E;
                border-color: #FF008E;
                color: #fff;
            }
        }
        &.style-2 {
            display: block;
            margin-top: 40px;
        }
    }
    
    .main-content {
        margin-right: 50px;

    }
    .side-bar {
        .widget {
            margin-bottom: 30px;
            padding: 24px;
            background-color: var(--primary-color6);
            border-radius: 16px;
            border: 1px solid var(--primary-color15);
            .widget-title {
                padding: 16px;
                background-color:var(--primary-color13) ;
                color:var(--primary-color14) ;
                margin-bottom: 30px;
                border-radius: 12px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.widget-category {
                li {
                    @include flexitem;
                    padding-bottom: 24px;
                    padding-top: 24px;
                    border-bottom:1px solid var(--primary-color17);
                    span {
                        margin-left: auto;
                        color: var(--primary-color16);
                        font-size: 18px;
                        line-height: 26px;
                    }
                    a {
                        font-size: 18px;
                        line-height: 26px;
                        color: var(--primary-color16);
                        &:hover {
                            color: var(--primary-color3);
                        }
                    }
                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                }

            }
            &.widget-recent-post {
                li {
                    @include flexitem;
                    padding-bottom: 19px;
                    padding-top: 19px;
                    border-bottom:1px solid var(--primary-color17);
                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                    .post-img {
                        flex-shrink: 0;
                        border-radius: 8px;
                        overflow: hidden;
                        margin-right: 16px;
                        img {
                            border-radius: 8px;
                        }
                    }
                    .post-content {
                        .title {
                            font-size: 18px;
                            line-height: 26px;
                            margin-bottom: 8px;
                            a  {
                                color: var(--primary-color16);
                                &:hover {
                                    color: var(--primary-color3);
                                }
                            }
                        }
                        .post-category {
                            font-size: 14px;
                            line-height: 22px;
                            color: var(--primary-color8);
                        }
                    }
                }
            }
            &.widget-tag {
                .widget-title {
                    margin-bottom: 28px;
                }
                ul {
                    @include flexitem;
                    flex-wrap: wrap;
                    margin-right: -8px;
                    li {
                        margin-right: 8px;
                        margin-bottom: 11px;
                        a {
                            padding: 0 11px;
                            border-radius: 20px;
                           @include flex(center,center);
                           color: var(--primary-color7);
                           border: 1px solid var(--primary-color4);
                           line-height: 35px;
                           font-weight: 700;
                           &:hover {
                                background-color: var(--primary-color3);
                                color: #fff;
                           }
                        }
                        &:nth-child(2) {
                            a {
                                padding: 0 20px;
                            }
                        }
                        &:nth-child(3) {
                            a {
                                padding: 0 20px;
                            }
                        }
                        &:nth-child(4) {
                            a {
                                padding: 0 9px;
                            }
                        }
                        &:nth-child(5) {
                            a {
                                padding: 0 19px;
                            }
                        }
                        &:nth-child(6) {
                            a {
                                padding: 0 10px;
                            }
                        }
                        &:nth-child(7) {
                            a {
                                padding: 0 20px;
                            }
                        }
                        &:nth-child(8) {
                            a {
                                padding: 0 20px;
                            }
                        }
                        &:nth-child(9) {
                            margin-right: 0;
                            a {
                                padding: 0 14px;
                            }
                        }

                    }
                }
            }
        }
    }
}

.is_dark {
    .tf-blog-item {
        background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.1) 0.07%, rgba(100, 97, 113, 0.037) 99.07%);
        border: 1px solid transparent;
    }
}

.tf-blog-detail {
    padding: 28px 0 102px 0;
}
.detail-inner {
    .content-top {
        text-align: center;
        margin-bottom: 24px;
        .title {
            margin-bottom: 24px;
            color: var(--primary-color5);
            padding:  0 40px;
        }
        .author-infor {
            @include flex(center,center);
            .image {
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 0;
                margin-right: 12px;
            }
            span,a {
                margin-right: 5px;
            }
            a {
                margin-right: 9px;
            }
            .mr9 {
                margin-right: 9px;
            }
            
        }
    }
    .image {
        margin-bottom: 32px;
        &.style-2 {
            margin-right: -20px;
            img.mr20 {
                margin-right: 17px;
            }
        }
    }
    .content-inner {
        margin-bottom: 32px;
        .title {
            margin-bottom: 16px;
            color: var(--primary-color5);
        }
        p {
            color: var(--primary-color18);
            font-size: 18px;
            line-height: 26px;
        }
    }
    .content-bottom {
        @include flex(center,space-between) ;
        align-items: center;
        padding-bottom: 38px;
        margin-top: -7px;
        border-bottom: 1px solid var(--primary-color21);
        margin-bottom: 40px;
        .widget {
            @include flex(center,space-between) ;
            .widget-title {
                font-size: 18px;
                line-height: 26px;
                margin-right: 14px;
                color: var(--primary-color5);
            }
            ul{
                @include flexitem;
                li {
                    margin-right: 11px;
                    a {
                        color: var(--primary-color19);
                        &:hover {
                            color: var(--primary-color3);
                        }
                    }
                   
                }
            }
            &.widget-socical {
                ul{
                    li:nth-child(2) {
                        a {
                           font-size: 12px;
                        }
                    }
                }
            }
            &.widget-tag {

                ul{
                    li {
                        margin-right: 0px;
                        a {
                            margin-right: 4px;
                            color: var(--primary-color20);
                            &:hover {
                                color: var(--primary-color3);
                            }
                        }
                        &:last-child {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        
    }
    #comments {
        .heading {
            margin-bottom: 24px;
        }
        .comment-form {
            fieldset {
                float: left;
                width: 50%;
                margin-bottom: 20px;
                &.name {
                    padding-right: 10px;
                }
                &.email {
                    padding-left: 10px;
                }
                &.message {
                    width: 100%;
                    margin-bottom: 23px;
                }
            }
            .tf-button {
                border-radius: 50px;
                background-color: #FF008E;
                color: #fff;
                border: none;
                padding: 12px 26px
            }
        }
    }
}

.detail-wrap {
    @include flexitem;
    .detail-inner {
        width: 74.47%;
        padding-right: 60px;
        .content-top {
            text-align: left;
            margin-bottom: 30px;
            .title {
                padding:0 0 23px 0;
                margin-bottom: 17px;
                border-bottom: 1px solid var(--primary-color21);
            }
            .meta-blog { 
                @include flex(center,space-between);
                .meta {
                    &.meta-right {
                        @include flex(center,space-between);
                        .meta-inner:first-child {
                            padding-right: 24px;
                            border-right: 1px solid var(--primary-color3);
                        }
                        .meta-inner:last-child {
                            padding-left: 23px;
                        }
                    }
                    h6 {
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--primary-color20);
                    }
                    p {
                        color: var(--primary-color8);
                        font-size: 12px;
                        line-height: 20px;
                        
                    }
                    
                    
                }
            }
        }
        .content-bottom {
            margin-top: -6px;
            padding-bottom: 40px;
        }
    }
    .side-bar {
        width: 25.53%;
        .widget {
            padding: 0;
            background-color: transparent;
            border: none;
            margin-bottom: 36px;
            .widget-title {
                background-color: transparent;
                padding: 0;
                color: var(--primary-color5);
                margin-bottom: 23px;
            }
            &.widget-recent-post {
                li {
                    align-items: center;
                    background-color: var(--primary-color22);
                    padding: 10px;
                    border-bottom: none;
                    margin-bottom: 12px;
                    border-radius: 8px;
                    &:first-child {
                        padding-top: 8px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 12px;
                    }
                    .post-img {
                        flex-shrink: 0;
                        margin-right: 13px;
                    }
                    .post-content {
                        width: 86%;
                        .title {
                            font-size: 14px;
                            line-height: 22px;
                            margin-bottom: 4px;
                        }
                        .post-meta {
                            @include flex(center,space-between);
                            span {
                                font-size: 12px;
                                line-height: 20px;
                                color: var(--primary-color23);
                            }
                        }
                    }
                }
            }
            &.widget-tag {
                .widget-title {
                    margin-bottom: 24px;
                }
                ul li {
                    a {
                        font-size: 14px;
                        line-height: 34px;
                        font-weight: 400;
                        padding: 0 12px;
                        background-color: var(--primary-color22);
                        border: none;
                    }
                    &:nth-child(2),&:nth-child(3) {
                        a {
                            padding: 0 20px;
                        }
                    }
                    &:nth-child(4) {
                        a {
                            padding: 0 10px;
                        }
                    }
                    &:nth-child(9) {
                        a {
                            padding: 0 18px;
                        }
                    }
                    &:nth-child(6) {
                        a {
                            padding: 0 12px;
                        }
                    }
                }
            }
        }
    }
}